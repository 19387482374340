<template>
  <div class="section bg-light text-dark py-4">
    <div class="container">
      <div class="row">
        <div class="col-9 col-lg-3 mt-3 text-center">
        <a href="https://g.page/umzug-bayern/review?rc"><img style="max-height:80px" :src="require('@/assets/umzug-bayern-google-rating.webp')" alt="Umzug Bayern bei Google bewerten"></a>
    </div>
    <div class="col-9 col-lg-3 mt-3 text-center">
        <div class="trustpilot-widget" data-locale="de-DE" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="5ed6277829b9d400013f9aaf" data-style-height="52px" data-style-width="100%">
        <a href="https://de.trustpilot.com/review/www.umzug-bayern.com" target="_blank" rel="noopener">Trustpilot</a>
      </div>
    </div>
    <div class="col-9 col-lg-3 mt-3 text-center">
      <a  href="https://umzug.check24.de/umzug/profil/raopam" target="_blank" title="zum CHECK24 Profi Profil"><img src="https://cdn.profis.check24.de/widget/2019.svg" alt="CHECK24 Profi Siegel" height="111" width="128" /></a>
    </div>
      </div>
    </div> 
  </div>
  <div class="section bg-primary text-light">
    <div class="container">
      <div class="row">
        <div class="col-6 col-md-8 col-lg-9 py-2 justify-content-center align-self-center px-2">
          <h3>Haben Sie noch Fragen?</h3>
          <MDBBtn class="mt-auto" tag="a" href="tel:+4915730438580" title="Anrufen" @click="PhoneCallClicked" color="dark" size="lg"><MDBIcon icon="phone" size="1x" /> <span> </span>015730438580</MDBBtn>
        </div>
      
        <div class="col-6 col-md-4 col-lg-3 justify-content-end align-self-end"><img style="height: auto; width:60%; margin-top: -200px;" :src="require('@/assets/umzugshelfer-münchen-umzug-bayern3.png')" fluid alt="umzugshelfer münchen umzug bayern" title="umzugshelfer münchen umzug bayern" loading="lazy"/></div>
        
      </div>
    </div> 
  </div>
</template>

<script>
import { MDBBtn } from "mdb-vue-ui-kit";
//import { MDBCard, MDBCardImg, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBIcon } from 'mdb-vue-ui-kit'

export default {
  name: "RatingBanner",
  components: {
    MDBBtn
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
